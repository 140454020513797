
import {
  mapState,
  mapMutations
} from "vuex";
export default {
  computed: {
    ...mapState([
      "vuex_app_serve",
      "vuex_device",
      "vuex_config",
      "vuex_status_bar_height",
      "vuex_system",
      "vuex_include_arr",
      "vuex_show_buy",
      "vuex_show_general",
      "vuex_user_info",
      "vuex_individualization"
    ]),
  },
  scrollToTop: true,
  name: "IndexPage",
  data() {
    return {
      cate_list: [{
        thumb: "https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/h5https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/mini_static/qi/school.png",
        url: "/school/list",
        name: "查大学",
        tab: '热门',
        sign_show: 1,
      },
      {
        thumb: "https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/h5https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/mini_static/qi/major.png",
        url: "/major/",
        name: "找专业",
        sign_show: 2,
      },
      {
        thumb: "https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/h5https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/mini_static/qi/rank.png",
        url: "/tools/subsection",
        name: "分段表",
      },
      {
        thumb: "https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/h5https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/mini_static/qi/bline.png",
        url: "/tools/batch",
        name: "批次线",
      },
      {
        thumb: "https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/h5https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/mini_static/qi/bline.png",
        url: "/tools/rank",
        name: "院校榜单",
      },
      {
        thumb: "https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/h5https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/mini_static/qi/light.png",
        url: "/tools/counter",
        name: "闪电估分",
        tab: '热门',
      },
      {
        thumb: "https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/h5https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/mini_static/qi/general.png",
        url: "/tools/general",
        name: "招生章程",
      },
      {
        thumb: "https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/h5https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/mini_static/qi/ai.png",
        url: "/filling/table?type=ai",
        name: "智能填报",
      },
      {
        thumb: "https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/h5https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/mini_static/qi/school.png",
        url: "/school/list?type=contrast",
        name: "院校对比",
      },
      {
        thumb: "https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/h5https://kuaitianyikao.oss-cn-hangzhou.aliyuncs.com/mini_static/qi/general.png",
        url: "/wiki/",
        name: "高考百科",
      },
      ],
      tools: ["找大学", "选专业", "测文化", "查位次", "算投档", "本省专区", "艺考日历", "录取概率", "分数线", "新手指南"],
      statusbar: 0,
      canvas: {

      },
      day: '',
      user_census: {},
      progress: 50, //进度条
      recommend_school_list: [], // 推荐院校列表
      information_list: [], //新闻列表
      recommend_number: {
        bao_number: '-',
        ben_number: '-',
        chong_number: '-',
        cwb_number: 0,
        sum_number: '-',
        tianbao_mode: '-',
        wen_number: '-',
        zhuan_number: '-'
      }, //冲稳保情况
      active: 0,
      myApi: "",
      value: "",
      showNav: false,
    };
  },
  mounted() {
    if (this.vuex_app_serve == 'uniapp') {
      window.uni.switchTab({
        url: '/pages/index/index'
      })
    }
    let that = this;
    this.$nextTick(() => {
      window.scrollTo(0, 0)
      // document.documentElement.scrollTop  = 0;
      // document.body.scrollTop = 0;
      // this.initScroll()
      window.onscroll = function () {
        //变量scrollTop是滚动条滚动时，距离顶部的距离
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop >= 60) {
          that.showNav = true;
        } else {
          that.showNav = false;
        }
      };
    });
    let vuex_include_arr = [...this.vuex_include_arr];
    let index = vuex_include_arr.indexOf("schoolList");
    if (index != -1) {
      vuex_include_arr.splice(index, 1);
    }
    this.set_include_arr(vuex_include_arr);
  },

  // async asyncData({
  //   app,
  //   query,
  //   params
  // }) {
  //   let postparams = {
  //     page: 1,
  //     pageSize: 15,
  //     classify_id: 3,
  //   };
  //   let [information_list, user_census, recommend_number, recommend_school_list] = await Promise.all(
  //     [
  //       await app.$axios.$post("/app/artapi.php?r=information.get_information_list", postparams),
  //       await app.$axios.$post("/app/artapi.php?r=user.user_census"),
  //       await app.$axios.$post("/app/artapi.php?r=v2.recommend.get_recommend_number&type=2"),
  //       await app.$axios.$post("/app/artapi.php?r=user.get_recommend_school_list"),
  //     ]
  //   );
  //   console.log(recommend_number);
  //   console.log(user_census);
  //   return {
  //     // statistic: statistic.data,
  //     // research_report1: research_report1.data,
  //     // research_report4: research_report4.data,
  //     information_list: information_list.data,
  //     user_census: user_census.data,
  //     recommend_number: recommend_number.data,
  //     recommend_school_list: recommend_school_list.data
  //   };
  // },
  created() {
    if (process.client) {
      this.get_recommend_number();
      this.get_recommend_school_list();
      this.get_information_list();
    }
  },
  methods: {
    ...mapMutations(["set_include_arr"]),
    get_information_list() {
      let postparams = {
        page: 1,
        pageSize: 15,
        classify_id: 3,
      };
      this.$axios.$post("/app/artapi.php?r=information.get_information_list", postparams).then((res) => {
        this.information_list = res.data;
      })
    },
    // get_user_census() {
    //   this.$axios.$post("/app/artapi.php?r=user.user_census").then((res) => {
    //     this.user_census = res.data;
    //     if(res.data.baokao_num){
    //     }
    //   })
    // },
    get_recommend_number() {
      this.$axios.$post("/app/artapi.php?r=v2.recommend.get_recommend_number&type=2").then((res) => {
        this.recommend_number = res.data;
        if (res.data && res.data.sum_number) {
          this.$store.dispatch("getUserInfo"); // 获取用户信息
        }
      })
    },
    get_recommend_school_list() {
      this.$axios.$post("/app/artapi.php?r=user.get_recommend_school_list").then((res) => {
        this.recommend_school_list = res.data;
      })
    },
    toUrl(e) {
      if (e.jump_type == 0) {
        this.$router.push({
          path: e.jump_url
        })
      }
    },
    toList() {
      let url = "/school/list";
      switch (this.active) {
        case 0:
          break;
        case 1:
          url = "/major/list";
          break;
        case 2:
          url = "/tools/general";
          break;
        default:
          break;
      }
      this.$router.push({
        path: url,
      });
    },
    toWebdiv(url) {
      this.$router.push({
        path: url,
      });
    },
    toNews() {
      this.$router.push({
        path: "/informationlist/3.html?page=1",
      });
    },
  },
};
